import React from "react";
import styled from "styled-components";
import translate from "../providers/i18n/translate";
import { Modal, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import SignatureLayout from "../components/signature/SignatureLayout";
import SaveSignature from "../components/signature/SaveSignature";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";
import { alertConstants } from "../store/reducers/messageBar/alertConstants";
import { useAuth } from "../providers/authentication";
import { decodeToken } from "../hooks/jwt";
import { API_URL } from "../config";
import SignaturesList from "../components/signature/SignaturesList";

/* Styled components */
const Root = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  height: 100%;
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const SignatureForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px;
  width: 100%;
`;

const StyledTextfield = styled(TextField)`
  width: ${(props) => (props.width ? props.width : "inherit")};
`;

const SignatureModel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`;

const SignatureTemplate = styled.div`
  transform: scale(0.5);
  align-self: center;
  @media (min-width: 768px) {
    transform: scale(1);
    align-self: flex-start;
  }
`;

const EmailResult = styled.textarea`
  padding: 10px;
  font-size: 0.65rem;
`;

const Actions = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
`;

const StyledButton = styled.button`
  padding: 8px 16px;
  background: ${(props) => props.theme.palette.accent};
  border: none;
  color: ${(props) => props.theme.palette.base};
  font-weight: 600;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
`;

const InputGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

export default function SignatureCreatorPage() {
  const signatureListRef = React.useRef();
  const intl = useIntl();
  const [data, setData] = React.useState({
    fullname: "",
    position: "",
    phone: "",
    address: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    logo: "http://api.alianzafrancesa.org.ar/uploads/images/mm/firmas/2020/Firma%202020-01.png",
  });

  const [id, setId] = React.useState(null);
  const [signatureName, setSignatureName] = React.useState(null);
  const { token } = useAuth();

  const userData = decodeToken(token);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    onSave(signatureName);
  };

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onDownloadSignature = () => {
    const element = document.createElement("a");
    const file = new Blob([template], { type: "text/html;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = "newSignature.html";
    document.body.appendChild(element);
    element.click();
  };

  const copyToClipboard = () => {
    var textField = document.createElement("textarea");
    textField.innerText = template;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const template = `
  <table style="height: 185px; width: 600px; border-top: 1px solid #808080;">
      <tbody>
        <tr>
          <td style="width: 200px;">
            <img
              src="${data.logo}"
              moz-do-not-send="true"
              width="190"
              height="176"
            />
          </td>
          <td style="width: 400px;">
            <div style="text-align: left;">
              <font size="2" color="#808080">
                <b>
                  <i>
                    <br />
                  </i>
                </b>
              </font>
            </div>
            <div style="text-align: left;">
              <b>${data.fullname}</b>
            </div>
            <div style="text-align: left;">
              <b style="color: #808080; font-size: small;">
                <i>${data.position}</i>
              </b>
            </div>
            <div style="text-align: left;">
              <div>
                <font size="1" color="#808080">
                  ${data.phone}
                </font>
              </div>
              <div>
                <font size="1" color="#808080">
                  ${data.address}
                </font>
              </div>
            </div>
            <div style="text-align: left;">
              <font size="1">
                <a
                  href="https://www.alianzafrancesa.org.ar/"
                  moz-do-not-send="true"
                >
                  <font color="#999999">
                    <font color="#808080">www.alianzafrancesa.org.ar</font>
                  </font>
                </a>
              </font>
            </div>
            <div style="display: flex; gap: 10px;">
              ${
                data.facebook && data.facebook !== ""
                  ? `<div style="margin: 5px;">
            <a
              href="${data.facebook}"
              moz-do-not-send="true"
            >
              <img
                src="https://api.alianzafrancesa.org.ar/uploads/images/mm/Redes%20Sociales/fb-art.png"
                moz-do-not-send="true"
                width="15"
                height="15"
              />
            </a>
          </div>`
                  : ""
              }
              ${
                data.twitter && data.twitter !== ""
                  ? `<div style="margin: 5px;">
            <a
              href="${data.twitter}"
              moz-do-not-send="true"
            >
              <img
                src="https://api.alianzafrancesa.org.ar/uploads/images/mm/Redes%20Sociales/f15ac5d59c61e34452a14f16546eb8a154c18d3e.png"
                moz-do-not-send="true"
                width="15"
                height="15"
              />
            </a>
          </div>`
                  : ""
              }
              ${
                data.instagram && data.instagram !== ""
                  ? `<div style="margin: 5px;">
            <a
              href="${data.instagram}"
              moz-do-not-send="true"
            >
              <img
                src="https://api.alianzafrancesa.org.ar/uploads/images/mm/INSTRA.jpg"
                moz-do-not-send="true"
                width="15"
                height="15"
              />
            </a>
          </div>`
                  : ""
              }
              ${
                data.youtube && data.youtube !== ""
                  ? `<div style="margin: 5px;">
            <a
              href="${data.youtube}"
              moz-do-not-send="true"
            >
              <img
                src="https://api.alianzafrancesa.org.ar/uploads/images/mm/Redes%20Sociales/YouTube-icon-full_color.png"
                moz-do-not-send="true"
                width="22"
                height="15"
              />
            </a>
          </div>`
                  : ""
              }
              ${
                data.linkedin && data.linkedin !== ""
                  ? `<div style="margin: 5px;">
            <a
              href="${data.linkedin}"
              moz-do-not-send="true"
            >
              <img
                src="https://api.alianzafrancesa.org.ar/uploads/images/Linkedin250.webp"
                moz-do-not-send="true"
                width="15"
                height="15"
              />
            </a>
          </div>`
                  : ""
              }
            </div>
            <div style="display: flex; gap: 10px; width: 100%; height: auto;">
              <a
                href="https://volte.earth/lp1.php?utm_project=184&partner=1&utm_source=direct&utm_medium=emailsignature"
                moz-do-not-send="true"
              >
                <img
                    src="https://api.alianzafrancesa.org.ar/uploads/images/Alliance_Banner.webp"
                    moz-do-not-send="true"
                    width="100%"
                    height="auto"
                />
              </a>
            </div>
          </td>
        </tr>
        <tr>
        <td colspan="2">
        <img
        src="https://api.alianzafrancesa.org.ar/uploads/images/1741713394152-firma_digital_af_futuro.webp"
          moz-do-not-send="true"
          width="370"
          height="148"
      />
        </td>
        </tr>
      </tbody>
    </table>
  `;

  const loadSignature = (data) => {
    setId(data.id);
    setSignatureName(data.name);
    setData(
      data.metadata
        ? {
            ...data.metadata,
            logo:
              data.metadata.logo ??
              "http://api.alianzafrancesa.org.ar/uploads/images/mm/firmas/2020/Firma%202020-01.png",
          }
        : {
            fullname: "",
            position: "",
            phone: "",
            address: "",
            facebook: "",
            twitter: "",
            instagram: "",
            youtube: "",
            linkedin: "",
            logo: "http://api.alianzafrancesa.org.ar/uploads/images/mm/firmas/2020/Firma%202020-01.png",
          }
    );
  };

  const onCreate = (name) => {
    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("metadata", JSON.stringify(data));
    formdata.append("content", template);
    formdata.append("createdUser", userData?.id);
    axios
      .post(`${API_URL}/signatures`, formdata, {})
      .then((res) => {
        alertMessage(
          intl.formatMessage({ id: "signature created" }),
          alertConstants.INFO
        );
        signatureListRef.current.clean();
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  const onSave = () => {
    var formdata = new FormData();
    formdata.append("metadata", JSON.stringify(data));
    formdata.append("content", template);
    formdata.append("updatedUser", userData?.id);
    axios
      .put(`${API_URL}/signatures/${id}`, formdata, {})
      .then((res) => {
        alertMessage(
          intl.formatMessage({ id: "signature updated" }),
          alertConstants.INFO
        );
        signatureListRef.current.clean();
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  return (
    <Root>
      <Container>
        <SignaturesList ref={signatureListRef} loadSignature={loadSignature} />
        <SignatureForm>
          <h1>{translate("signature creator")}</h1>
          <InputGroup>
            <StyledTextfield
              id="fullname"
              name="fullname"
              onChange={handleChange}
              value={data.fullname}
              label={intl.formatMessage({ id: "fullname" })}
              variant="outlined"
              width="50%"
              margin="dense"
              size="small"
            />
            <StyledTextfield
              id="position"
              name="position"
              onChange={handleChange}
              value={data.position}
              label={intl.formatMessage({ id: "position" })}
              variant="outlined"
              width="50%"
              margin="dense"
              size="small"
            />
          </InputGroup>
          <InputGroup>
            <StyledTextfield
              id="phone"
              name="phone"
              onChange={handleChange}
              value={data.phone}
              label={intl.formatMessage({ id: "phone" })}
              variant="outlined"
              width="50%"
              margin="dense"
              size="small"
            />
            <StyledTextfield
              id="address"
              name="address"
              onChange={handleChange}
              value={data.address}
              label={intl.formatMessage({ id: "address" })}
              variant="outlined"
              width="50%"
              margin="dense"
              size="small"
            />
          </InputGroup>
          <InputGroup>
            <StyledTextfield
              id="facebook"
              name="facebook"
              onChange={handleChange}
              value={data.facebook}
              label={intl.formatMessage({ id: "facebook" })}
              variant="outlined"
              width="50%"
              margin="dense"
              size="small"
            />
            <StyledTextfield
              id="twitter"
              name="twitter"
              onChange={handleChange}
              value={data.twitter}
              label={intl.formatMessage({ id: "twitter" })}
              variant="outlined"
              width="50%"
              margin="dense"
              size="small"
            />
          </InputGroup>
          <InputGroup>
            <StyledTextfield
              id="instagram"
              name="instagram"
              onChange={handleChange}
              value={data.instagram}
              label={intl.formatMessage({ id: "instagram" })}
              variant="outlined"
              width="50%"
              margin="dense"
              size="small"
            />
            <StyledTextfield
              id="youtube"
              name="youtube"
              onChange={handleChange}
              value={data.youtube}
              label={intl.formatMessage({ id: "youtube" })}
              variant="outlined"
              width="50%"
              margin="dense"
              size="small"
            />
          </InputGroup>
          <StyledTextfield
              id="linkedin"
              name="linkedin"
              onChange={handleChange}
              value={data.linkedin}
              label={intl.formatMessage({ id: "linkedin" })}
              variant="outlined"
              width="50%"
              margin="dense"
              size="small"
            />
          {/* <StyledTextfield
            id="logo"
            name="logo"
            onChange={handleChange}
            value={data.logo}
            label={intl.formatMessage({ id: "logo" })}
            variant="outlined"
            width="100%"
            margin="dense"
            size="small"
          /> */}
        </SignatureForm>
        <SignatureModel>
          <SignatureLayout>
            <SignatureTemplate dangerouslySetInnerHTML={{ __html: template }} />
          </SignatureLayout>
          <EmailResult readonly rows="15" value={template} />
          <Actions>
            <StyledButton onClick={onDownloadSignature}>
              {translate("download")}
            </StyledButton>
            <StyledButton onClick={copyToClipboard}>
              {translate("copy code")}
            </StyledButton>
            <StyledButton disabled={!id} onClick={handleSave}>
              {translate("save")}
            </StyledButton>
            <StyledButton onClick={handleOpen}>
              {translate("save as")}
            </StyledButton>
          </Actions>
        </SignatureModel>
        <Modal open={open} onClose={handleClose}>
          <SaveSignature onSave={onCreate} handleClose={handleClose} />
        </Modal>
      </Container>
    </Root>
  );
}
